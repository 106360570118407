import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

function kullaniciSozlesme() {
  React.useEffect(() => {
    document.title = "Kullanıcı Sözleşmesi";
  }, []);
  return (
    <Container className="hakkimizda">
      <Row>
        <Card>
          <Card.Body>
            <p>
              <b>SİTE KULLANIM ŞARTLARI</b>
            </p>

            <p>
              Lütfen sitemizi kullanmadan önce ‘Site Kullanım Şartları’nı
              dikkatlice okuyunuz.&nbsp;
            </p>

            <p>
              www.siriusalgo.com kullanan ve alışveriş yapan müşterilerimiz
              aşağıdaki şartları kabul etmiş varsayılmaktadır:
            </p>

            <p>
              Sitemizdeki web sayfaları ve ona bağlı tüm sayfalar
              (www.siriusalgo.com) adresinde hizmet veren Orphan Yazılım Bilişim
              Ar-Ge Akademi firmasının (Firma) malıdır ve onun tarafından
              işletilir. Sizler (‘Kullanıcı’) sitede sunulan tüm hizmetleri
              kullanırken aşağıdaki şartlara tabi olduğunuzu, sitedeki hizmetten
              yararlanmakla ve kullanmaya devam etmekle; Bağlı olduğunuz
              yasalara göre sözleşme imzalama hakkına, yetkisine ve hukuki
              ehliyetine sahip ve 18 yaşın üzerinde olduğunuzu, bu sözleşmeyi
              okuduğunuzu, anladığınızı ve sözleşmede yazan şartlarla bağlı
              olduğunuzu kabul etmiş sayılırsınız.&nbsp;
            </p>

            <p>
              İşbu sözleşme taraflara sözleşme konusu site ile ilgili hak ve
              yükümlülükler, yükler ve taraflar işbu sözleşmeyi kabul
              ettiklerinde bahsi geçen hak ve yükümlülükleri eksiksiz, doğru,
              zamanında, işbu sözleşmede talep edilen şartlar dâhilinde yerine
              getireceklerini beyan ederler.
            </p>

            <p>
              <b>1.Sorumluluklar</b>
            </p>

            <p>
              a.Firma, fiyatlar ve sunulan ürün ve hizmetler üzerinde değişiklik
              yapma hakkını her zaman saklı tutar.&nbsp;
            </p>

            <p>
              b.Firma, üyenin sözleşme konusu hizmetlerden, teknik arızalar
              dışında yararlandırılacağını kabul ve taahhüt eder.
            </p>

            <p>
              c.Kullanıcı, sitenin kullanımında tersine mühendislik
              yapmayacağını ya da bunların kaynak kodunu bulmak veya elde etmek
              amacına yönelik herhangi bir başka işlemde bulunmayacağını aksi
              halde ve 3. Kişiler nezdinde doğacak zararlardan sorumlu
              olacağını, hakkında hukuki ve cezai işlem yapılacağını peşinen
              kabul eder.&nbsp;
            </p>

            <p>
              d.Kullanıcı, site içindeki faaliyetlerinde, sitenin herhangi bir
              bölümünde veya iletişimlerinde genel ahlaka ve adaba aykırı,
              kanuna aykırı, 3. Kişilerin haklarını zedeleyen, yanıltıcı,
              saldırgan, müstehcen, pornografik, kişilik haklarını zedeleyen,
              telif haklarına aykırı, yasa dışı faaliyetleri teşvik eden
              içerikler üretmeyeceğini, paylaşmayacağını kabul eder. Aksi halde
              oluşacak zarardan tamamen kendisi sorumludur ve bu durumda ‘Site’
              yetkilileri, bu tür hesapları askıya alabilir, sona erdirebilir,
              yasal süreç başlatma hakkını saklı tutar. Bu sebeple yargı
              mercilerinden etkinlik veya kullanıcı hesapları ile ilgili bilgi
              talepleri gelirse paylaşma hakkını saklı tutar.
            </p>

            <p>
              e.Sitenin üyelerinin birbirleri veya üçüncü şahıslarla olan
              ilişkileri kendi sorumluluğundadır.&nbsp;
            </p>

            <p>
              <b>2. &nbsp;Fikri Mülkiyet Hakları</b>
            </p>

            <p>
              2.1. İşbu Site’de yer alan ünvan, işletme adı, marka, patent,
              logo, tasarım, bilgi ve yöntem gibi tescilli veya tescilsiz tüm
              fikri mülkiyet hakları site işleteni ve sahibi firmaya veya
              belirtilen ilgilisine ait olup, ulusal ve uluslararası hukukun
              koruması altındadır. İşbu Site’nin ziyaret edilmesi veya bu
              Site’deki hizmetlerden yararlanılması söz konusu fikri mülkiyet
              hakları konusunda hiçbir hak vermez.
            </p>

            <p>
              2.2. Site’de yer alan bilgiler hiçbir şekilde çoğaltılamaz,
              yayınlanamaz, kopyalanamaz, sunulamaz ve/ veya aktarılamaz.
              Site’nin bütünü veya bir kısmı diğer bir internet sitesinde
              izinsiz olarak kullanılamaz.&nbsp;
            </p>

            <p>
              <b>3. Gizli Bilgi</b>
            </p>

            <p>
              <b>&nbsp;</b>
            </p>

            <p>
              3.1. Firma, site üzerinden kullanıcıların ilettiği kişisel
              bilgileri 3. Kişilere açıklamayacaktır. Bu kişisel bilgiler; kişi
              adı-soyadı, adresi, telefon numarası, cep telefonu, e-posta adresi
              gibi Kullanıcı’yı tanımlamaya yönelik her türlü diğer bilgiyi
              içermekte olup, kısaca ‘Gizli Bilgiler’ olarak anılacaktır.
            </p>

            <p>
              3.2. Kullanıcı, sadece tanıtım, reklam, kampanya, promosyon,
              duyuru vb. pazarlama faaliyetleri kapsamında kullanılması ile
              sınırlı olmak üzere, Site’nin sahibi olan firmanın kendisine ait
              iletişim, portföy durumu ve demografik bilgilerini iştirakleri ya
              da bağlı bulunduğu grup şirketleri ile paylaşmasına muvafakat
              ettiğini kabul ve beyan eder. Bu kişisel bilgiler firma bünyesinde
              müşteri profili belirlemek, müşteri profiline uygun promosyon ve
              kampanyalar sunmak ve istatistiksel çalışmalar yapmak amacıyla
              kullanılabilecektir.
            </p>

            <p>
              3.3. Gizli Bilgiler, ancak resmi makamlarca usulü dairesinde bu
              bilgilerin talep edilmesi halinde ve yürürlükteki emredici mevzuat
              hükümleri gereğince resmi makamlara açıklama yapılmasının zorunlu
              olduğu durumlarda resmi makamlara açıklanabilecektir.
            </p>

            <p>
              <b>4. Garanti Vermeme</b>
            </p>

            <p>
              <b>&nbsp;</b>
            </p>

            <p>
              İşbu sözleşme maddesi uygulanabilir kanunun izin verdiği azami
              ölçüde geçerli olacaktır. Firma tarafından sunulan hizmetler
              &quot;olduğu gibi” ve &quot;mümkün olduğu” temelde sunulmakta ve
              pazarlanabilirlik, belirli bir amaca uygunluk veya ihlal etmeme
              konusunda tüm zımni garantiler de dâhil olmak üzere hizmetler veya
              uygulama ile ilgili olarak (bunlarda yer alan tüm bilgiler dâhil)
              sarih veya zımni, kanuni veya başka bir nitelikte hiçbir garantide
              bulunmamaktadır.&nbsp;
            </p>

            <p>
              <b>5. Kayıt ve Güvenlik&nbsp;</b>
            </p>

            <p>
              Kullanıcı, doğru, eksiksiz ve güncel kayıt bilgilerini vermek
              zorundadır. Aksi halde bu Sözleşme ihlal edilmiş sayılacak ve
              Kullanıcı bilgilendirilmeksizin hesap kapatılabilecektir.
            </p>

            <p>
              Kullanıcı, site ve üçüncü taraf sitelerdeki şifre ve hesap
              güvenliğinden kendisi sorumludur. Aksi halde oluşacak veri
              kayıplarından ve güvenlik ihlallerinden veya donanım ve cihazların
              zarar görmesinden Firma sorumlu tutulamaz.
            </p>

            <p>
              <b>6. Mücbir Sebep</b>
            </p>

            <p>
              Tarafların kontrolünde olmayan; tabii afetler, yangın, patlamalar,
              iç savaşlar, savaşlar, ayaklanmalar, halk hareketleri, seferberlik
              ilanı, grev, lokavt ve salgın hastalıklar, altyapı ve internet
              arızaları, elektrik kesintisi gibi sebeplerden (aşağıda birlikte
              &quot;Mücbir Sebep” olarak anılacaktır.) dolayı sözleşmeden doğan
              yükümlülükler taraflarca ifa edilemez hale gelirse, taraflar
              bundan sorumlu değildir. Bu sürede Taraflar’ın işbu Sözleşme’den
              doğan hak ve yükümlülükleri askıya alınır.&nbsp;
            </p>

            <p>
              <b>7. Sözleşmenin Bütünlüğü ve Uygulanabilirlik</b>
            </p>

            <p>
              İşbu sözleşme şartlarından biri, kısmen veya tamamen geçersiz hale
              gelirse, sözleşmenin geri kalanı geçerliliğini korumaya devam
              eder.
            </p>

            <p>
              <b>8. Sözleşmede Yapılacak Değişiklikler</b>
            </p>

            <p>
              Firma, dilediği zaman sitede sunulan hizmetleri ve işbu sözleşme
              şartlarını kısmen veya tamamen değiştirebilir. Değişiklikler
              sitede yayınlandığı tarihten itibaren geçerli olacaktır.
              Değişiklikleri takip etmek Kullanıcı’nın sorumluluğundadır.
              Kullanıcı, sunulan hizmetlerden yararlanmaya devam etmekle bu
              değişiklikleri de kabul etmiş sayılır.
            </p>

            <p>
              <b>9. Tebligat</b>
            </p>

            <p>
              İşbu Sözleşme ile ilgili taraflara gönderilecek olan tüm
              bildirimler, Firma’nın bilinen e-posta adresi ve kullanıcının
              üyelik formunda belirttiği e-posta adresi vasıtasıyla
              yapılacaktır. Kullanıcı, üye olurken belirttiği adresin geçerli
              tebligat adresi olduğunu, değişmesi durumunda 5 gün içinde yazılı
              olarak diğer tarafa bildireceğini, aksi halde bu adrese yapılacak
              tebligatların geçerli sayılacağını kabul eder.
            </p>

            <p>
              <b>10. Delil Sözleşmesi</b>
            </p>

            <p>
              Taraflar arasında işbu sözleşme ile ilgili işlemler için
              çıkabilecek her türlü uyuşmazlıklarda Taraflar’ın defter, kayıt ve
              belgeleri ile ve bilgisayar kayıtları ve faks kayıtları 6100
              sayılı Hukuk Muhakemeleri Kanunu uyarınca delil olarak kabul
              edilecek olup, kullanıcı bu kayıtlara itiraz etmeyeceğini kabul
              eder.
            </p>

            <p>
              <b>11. Uyuşmazlıkların Çözümü</b>
            </p>

            <p>
              İşbu Sözleşme’nin uygulanmasından veya yorumlanmasından doğacak
              her türlü uyuşmazlığın çözümünde İstanbul (Merkez) Adliyesi
              Mahkemeleri ve İcra Daireleri yetkilidir.
            </p>
          </Card.Body>
        </Card>
      </Row>
    </Container>
  );
}

export default kullaniciSozlesme;
