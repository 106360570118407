import React, { useState, useEffect } from "react";
import {
  Nav,
  Navbar,
  Container,
  NavDropdown,
  Offcanvas,
} from "react-bootstrap";

import MenuPanel from "./menu";
import logo from "../img/logo.png";
import darklogo from "../img/dark-logo.png";

import { BiDoorOpen, BiMoon, BiSun } from "react-icons/bi";
import { TiWeatherNight, TiWeatherSunny } from "react-icons/ti";
import moment from "moment";
import { TickerTape } from "react-ts-tradingview-widgets";

const taperData = [
  {
    description: "BIST 100",
    proName: "BIST:XU100",
  },
  {
    description: "GRAM ALTIN",
    proName: "FX_IDC:XAUTRYG",
  },
  {
    description: "DOLAR",
    proName: "FX:USDTRY",
  },
  {
    description: "EURO",
    proName: "FX:EURTRY",
  },
  {
    description: "ONS ALTIN",
    proName: "FOREXCOM:XAUUSD",
  },
  {
    description: "NASDAQ",
    proName: "NASDAQ:NDX",
  },
  {
    description: "DOW JONES",
    proName: "FOREXCOM:DJI",
  },
];

function Header(props) {
  const subscription = localStorage.getItem("subscription").replaceAll('"', "");
  const remaining = localStorage.getItem("remaining");
  const paket = localStorage.getItem("package");
  const paket_id = localStorage.getItem("package_id");
  const [selectToken, setSelectToken] = useState(null);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const iconColor = props.theme === "light" ? "black" : "white";
  const icon =
    props.theme === "light" ? (
      <TiWeatherNight size={22} />
    ) : (
      <TiWeatherSunny size={22} />
    );
  async function isHeader() {
    let accessToken = await localStorage.getItem("access_token");
    setSelectToken(accessToken);
  }
  function OffcanvasExample() {
    return (
      <>
        <Offcanvas show={show} onHide={handleClose}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Menü</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className="nav">
            <MenuPanel theme={props.theme} changeTheme={props.changeTheme} />
          </Offcanvas.Body>
        </Offcanvas>
      </>
    );
  }
  useEffect(() => {
    isHeader();
  }, []);
  return (
    <Navbar
      bg={props.theme == "light" ? "light" : "dark"}
      variant={props.theme == "light" ? "light" : "dark"}
      expand="lg"
      className="navbarbg"
    >
      <Navbar.Collapse id="basic-navbar-nav" className="navSideMenu">
        <OffcanvasExample />
        {/* <div className="logoContainer">
            <img className="logo" src={props.theme == 'light' ? logo : darklogo} alt="Sirius Algo" />
          </div> */}
      </Navbar.Collapse>
      {selectToken ? (
        <>
          <div className="col text-end">
            <div className="row">
              <div className="col-lg-12">
                {subscription === "active" && (
                  <TickerTape
                    symbols={taperData}
                    displayMode="compact"
                    isTransparent={true}
                    colorTheme={props.theme == "light" ? "light" : "dark"}
                  ></TickerTape>
                )}
              </div>
              <div className="mobileToggle paketHeader push text-end col">
                <div className="mobileMenu">
                  <Navbar.Toggle
                    aria-controls="basic-navbar-nav"
                    onClick={handleShow}
                  />
                </div>
                <div className="mobileLogo">
                  <img
                    className="logo"
                    src={props.theme == "light" ? logo : darklogo}
                    alt="Sirius Algo"
                  />
                </div>
                <div className="mobileToggle">
                  <Nav.Link
                    className="d-inline profilLink mx-2"
                    href="/uye-cikis"
                  >
                    <BiDoorOpen /> Çıkış
                  </Nav.Link>
                  {
                    <span
                      className="darkMode pointer"
                      onClick={props.changeTheme}
                    >
                      {icon}
                    </span>
                  }
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </Navbar>
  );
}

export default Header;
