import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

function gizlilikPolitikasi() {
  React.useEffect(() => {
    document.title = "Gizlilik ve Güvenlik Politikası";
  }, []);
  return (
    <Container className="hakkimizda">
      <Row>
        <Card>
          <Card.Body>
            <p>
              <b>Gizlilik ve Güvenlik Politikası</b>
            </p>

            <p>
              Firmamızca verilen tüm servisler ve www.siriusalgo.com web
              adresinde kayıtlı Orphan Yazılım Bilişim Ar-Ge Ltd. Şti. firmamıza
              aittir ve firmamız tarafından işletilir.{" "}
            </p>

            <p>
              Firmamız, çeşitli amaçlarla kişisel veriler toplayabilir. Aşağıda,
              toplanan kişisel verilerin nasıl ve ne şekilde toplandığı, bu
              verilerin nasıl ve ne şekilde korunduğu belirtilmektedir.{" "}
            </p>

            <p>
              Üyelik veya web sitemiz üzerindeki çeşitli form ve anketlerin
              doldurulması suretiyle üyelerin kendileriyle ilgili bir takım
              kişisel bilgileri (isim-soy isim, firma bilgileri, telefon, adres
              veya e-posta adresleri gibi) firmamız tarafından toplanmaktadır.{" "}
            </p>

            <p>
              Firmamız bazı dönemlerde müşterilerine ve üyelerine kampanya
              bilgileri, yeni ürünler hakkında bilgiler, promosyon teklifleri
              gönderebilir. Üyelerimiz bu gibi bilgileri alıp almama konusunda
              her türlü seçimi üye olurken yapabilir, sonrasında üye girişi
              yaptıktan sonra hesap bilgileri bölümünden bu seçimi
              değiştirilebilir ya da kendisine gelen bilgilendirme iletisindeki
              linkle bildirim yapabilir.{" "}
            </p>

            <p>
              Web sitemiz üzerinden veya eposta ile gerçekleştirilen onay
              sürecinde, üyelerimiz tarafından firmamıza elektronik ortamdan
              iletilen kişisel bilgiler, Üyelerimiz ile yaptığımız
              &quot;Kullanıcı Sözleşmesi&quot; ile belirlenen amaçlar ve kapsam
              dışında üçüncü kişilere açıklanmayacaktır.
            </p>

            <p>
              Sistemle ilgili sorunların tanımlanması ve verilen hizmet ile
              ilgili çıkabilecek sorunların veya uyuşmazlıkların hızla çözülmesi
              için, Firmamız, üyelerinin IP adresini kaydetmekte ve bunu
              kullanmaktadır. IP adresleri, kullanıcıları genel bir şekilde
              tanımlamak ve kapsamlı demografik bilgi toplamak amacıyla da
              kullanılabilir.
            </p>

            <p>
              Kişisel bilgiler, gerektiğinde kullanıcıyla temas kurmak için de
              kullanılabilir. Firmamız tarafından talep edilen bilgiler veya
              kullanıcı tarafından sağlanan bilgiler veya firmamız üzerinden
              yapılan işlemlerle ilgili bilgiler; Firmamız ve işbirliği içinde
              olduğu kişiler tarafından, &quot;Üyelik Sözleşmesi&quot; ile
              belirlenen amaçlar ve kapsam dışında da, üyelerimizin kimliği
              anonim kalmak kaydıyla çeşitli istatistiksel değerlendirmeler,
              veri tabanı oluşturma ve pazar araştırmalarında kullanılabilir.
            </p>

            <p>
              Firmamız, gizli bilgileri kesinlikle özel ve gizli tutmayı, bunu
              bir sır saklama yükümü olarak addetmeyi ve gizliliğin sağlanması
              ve sürdürülmesi, gizli bilginin tamamının veya herhangi bir
              kısmının kamu alanına girmesini veya yetkisiz kullanımını veya
              üçüncü bir kişiye ifşasını önlemek için gerekli tüm tedbirleri
              almayı ve gerekli özeni göstermeyi taahhüt etmektedir.
            </p>

            <p>
              <b>Kredi Kartı Güvenliği</b>
            </p>

            <p>
              Firmamız, alışveriş sitelerimizden alışveriş yapan kredi kartı
              sahiplerinin güvenliğini ilk planda tutmaktadır. Kredi kartı
              bilgileriniz hiçbir şekilde sistemimizde saklanmamaktadır.
            </p>

            <p>
              İşlemler sürecine girdiğinizde güvenli bir sitede olduğunuzu
              anlamak için dikkat etmeniz gereken iki şey vardır. Bunlardan biri
              tarayıcınızın en alt satırında bulunan bir anahtar ya da kilit
              simgesidir. Bu güvenli bir internet sayfasında olduğunuzu gösterir
              ve her türlü bilgileriniz şifrelenerek korunur. Bu bilgiler, ancak
              satış işlemleri sürecine bağlı olarak ve verdiğiniz talimat
              doğrultusunda kullanılır. Alışveriş sırasında kullanılan kredi
              kartı ile ilgili bilgiler alışveriş sitelerimizden bağımsız olarak
              128 bit SSL (Secure Sockets Layer) protokolü ile şifrelenip
              sorgulanmak üzere ilgili bankaya ulaştırılır. Kartın
              kullanılabilirliği onaylandığı takdirde alışverişe devam edilir.
              Kartla ilgili hiçbir bilgi tarafımızdan görüntülenemediğinden ve
              kaydedilmediğinden, üçüncü şahısların herhangi bir koşulda bu
              bilgileri ele geçirmesi engellenmiş olur.
            </p>

            <p>
              Online olarak kredi kartı ile verilen siparişlerin ödeme/ fatura/
              teslimat adresi bilgilerinin güvenilirliği firmamız tarafından
              Kredi Kartları Dolandırıcılığı'na karşı denetlenmektedir. Bu
              yüzden, alışveriş sitelerimizden ilk defa sipariş veren
              müşterilerin siparişlerinin tedarik ve teslimat aşamasına
              gelebilmesi için öncelikle finansal ve adres/ telefon bilgilerinin
              doğruluğunun onaylanması gereklidir. Bu bilgilerin kontrolü için
              gerekirse kredi kartı sahibi müşteri ile veya ilgili banka ile
              irtibata geçilmektedir.
            </p>

            <p>
              Üye olurken verdiğiniz tüm bilgilere sadece siz ulaşabilir ve siz
              değiştirebilirsiniz. Üye giriş bilgilerinizi güvenli koruduğunuz
              takdirde başkalarının sizinle ilgili bilgilere ulaşması ve bunları
              değiştirmesi mümkün değildir. Bu amaçla, üyelik işlemleri
              sırasında 128 bit SSL güvenlik alanı içinde hareket edilir. Bu
              sistem kırılması mümkün olmayan bir uluslararası bir şifreleme
              standardıdır.
            </p>

            <p>
              <b>Üçüncü Taraf Web Siteleri ve Uygulamalar</b>
            </p>

            <p>
              Mağazamız, web sitesi dâhilinde başka sitelere link verebilir.
              Firmamız, bu linkler vasıtasıyla erişilen sitelerin gizlilik
              uygulamaları ve içeriklerine yönelik herhangi bir sorumluluk
              taşımamaktadır. Firmamıza ait sitede yayınlanan reklamlar,
              reklamcılık yapan iş ortaklarımız aracılığı ile kullanıcılarımıza
              dağıtılır. İş bu sözleşmedeki Gizlilik Politikası Prensipleri,
              sadece firmamızın kullanımına ilişkindir, üçüncü taraf web
              sitelerini kapsamaz.{" "}
            </p>

            <p>
              <b>İstisnai Haller</b>
            </p>

            <p>
              Aşağıda belirtilen sınırlı hallerde Firmamız, işbu &quot;Gizlilik
              Politikası&quot; hükümleri dışında kullanıcılara ait bilgileri
              üçüncü kişilere açıklayabilir. Bu durumlar sınırlı sayıda olmak
              üzere;
            </p>

            <p>
              1.Kanun, Kanun Hükmünde Kararname, Yönetmelik v.b. yetkili hukuki
              otorite tarafından çıkarılan ve yürürlükte olan hukuk kurallarının
              getirdiği zorunluluklara uymak;
            </p>

            <p>
              2.Firmamızın kullanıcılarla akdettiği &quot;Kullanıcı
              Sözleşmesi&quot;'nin ve diğer sözleşmelerin gereklerini yerine
              getirmek ve bunları uygulamaya koymak amacıyla;
            </p>

            <p>
              3.Yetkili idari ve adli otorite tarafından usulüne göre yürütülen
              bir araştırma veya soruşturmanın yürütümü amacıyla kullanıcılarla
              ilgili bilgi talep edilmesi;
            </p>

            <p>
              4.Kullanıcıların hakları veya güvenliklerini korumak için bilgi
              vermenin gerekli olduğu hallerdir.{" "}
            </p>

            <p>
              <b>E-Posta Güvenliği</b>
            </p>

            <p>
              Firmamızın Müşteri Hizmetleri’ne, herhangi bir siparişinizle
              ilgili olarak göndereceğiniz e-postalarda, asla kredi kartı
              numaranızı veya şifrelerinizi yazmayınız. E-postalarda yer alan
              bilgiler üçüncü şahıslar tarafından görülebilir. Firmamız
              e-postalarınızdan aktarılan bilgilerin güvenliğini hiçbir koşulda
              garanti edemez.
            </p>

            <p>
              <b>Çerez Politikası</b>
            </p>

            <p>
              Firmamız, web sitemizi ziyaret eden kullanıcılar ve kullanıcıların
              web sitesini kullanımı hakkındaki bilgileri teknik bir iletişim
              dosyası (Çerez-Cookie) kullanarak elde edebilir. Bahsi geçen
              teknik iletişim dosyaları, ana bellekte saklanmak üzere bir
              internet sitesinin kullanıcının tarayıcısına (browser) gönderdiği
              küçük metin dosyalarıdır. Teknik iletişim dosyası site hakkında
              durum ve tercihleri saklayarak İnternet'in kullanımını
              kolaylaştırır.
            </p>

            <p>
              Teknik iletişim dosyası, siteyi kaç kişinin ziyaret ettiğini, bir
              kişinin siteyi hangi amaçla, kaç kez ziyaret ettiğini ve ne kadar
              sitede kaldıkları hakkında istatistiksel bilgileri elde etmeye ve
              kullanıcılar için özel tasarlanmış kullanıcı sayfalarından dinamik
              olarak reklam ve içerik üretilmesine yardımcı olur. Teknik
              iletişim dosyası, ana bellekte veya e-postanızdan veri veya
              başkaca herhangi bir kişisel bilgi almak için tasarlanmamıştır.
              Tarayıcıların pek çoğu başta teknik iletişim dosyasını kabul eder
              biçimde tasarlanmıştır ancak kullanıcılar dilerse teknik iletişim
              dosyasının gelmemesi veya teknik iletişim dosyasının
              gönderildiğinde uyarı verilmesini sağlayacak biçimde ayarları
              değiştirebilirler.
            </p>

            <p>
              Firmamız, işbu &quot;Gizlilik Politikası&quot; hükümlerini
              dilediği zaman sitede yayınlamak veya kullanıcılara elektronik
              posta göndermek veya sitesinde yayınlamak suretiyle
              değiştirebilir. Gizlilik Politikası hükümleri değiştiği takdirde,
              yayınlandığı tarihte yürürlük kazanır.
            </p>

            <p>
              Gizlilik politikamız ile ilgili her türlü soru ve önerileriniz
              için info@ orphanakademi.com adresine e-posta gönderebilirsiniz.
              Firmamız’a ait aşağıdaki iletişim bilgilerinden ulaşabilirsiniz.
            </p>

            <p>Firma Orphan Yazılım Bilişim Ar-Ge Akademi Ltd. Şti</p>

            <p>Adres: Barbaros Bulvarı No:58 Balmumcu Beşiktaş - İstanbul</p>

            <p>
              Eposta:
              <a href="mailto:info@orphanakademi.com">info@orphanakademi.com</a>
            </p>
            <p>Tel: 0530 2586016</p>
          </Card.Body>
        </Card>
      </Row>
    </Container>
  );
}

export default gizlilikPolitikasi;
