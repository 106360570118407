import React, { useState } from "react";
import axios from "axios";
let date      = new Date();
var timestamp = date.getTime();

export const DataContext = React.createContext();

export const DataProvider = ({ children }) => {
  const access_token = localStorage.getItem("access_token");
  const [isProcessing, setIsProcessing] = useState(false);
  const [formations, setFormations] = useState([]);
  const [periyots, setPeriyots] = useState([]);
  const [markets, setMarkets] = useState([]);
  function mapCallback(item) {
    return {
      value: item.name,
      label: item.name,
      isDisabled:false,
    };
  }
  function marketCallback(item) {
    return {
      value: item.id,
      label: item.symbol,
      exchange_id: item.exchange_id,
    };
  }
  function periyotCallback(item) {
    return {
      value: item.name,
      label: item.description,
    };
  }
  const _getFormastions = async (urlSystem) => {
    setIsProcessing(true);
    const postData = {
      access_token: access_token.replaceAll('"', ""),
    };
    let res = await axios({
      method: "post",
      url: `${urlSystem}formations.php?_=${timestamp}`,
      data: postData,
    });
    if (res.status === 200) {
      // test for status you want, etc
      console.log(res.status);
    }
    // console.log("userInfo", res.data);
    // Don't forget to return something
    if (res.data.code === 0) {
      localStorage.setItem("subscription",JSON.stringify(res.data.data.subscription));
      localStorage.setItem("remaining", JSON.stringify(res.data.data.remaining));
      localStorage.setItem("package", JSON.stringify(res.data.data.package));
      localStorage.setItem("package_id", JSON.stringify(res.data.data.package_id));
      var dataDummy = [];
      dataDummy.push({
        items: res.data.data.formations.map(mapCallback),
      });

      // console.log("formations completed: ", dataDummy[0]);
      setFormations(dataDummy[0]);
      setIsProcessing(false);
    } else {
      if (res.data.code >0) {
        toast.error(res.data.data);
        //toast.warning("Lütfen tekrar giriş yapınız!");
        setInterval(() => {
          navigate("/uye-cikis");
        }, 1000);
      }
    }
  };

  const _getMarkets = async (urlSystem) => {
    setIsProcessing(true);

    let counter = 5;
    while (true) {
      try {
        const urlSystemDetay = `${urlSystem}markets.php?_=${timestamp}`;
        const formationResponse = await axios.get(urlSystemDetay);
        var dataDummy = [];
        dataDummy.push({
          items: formationResponse.data.data.map(marketCallback),
        });

        // console.log("markets completed: ", dataDummy[0]);
        setMarkets(dataDummy[0]);
        setIsProcessing(false);
        break;
      } catch (error) {
        if (counter === 0) {
          console.log("markets network error: ", error);
          setIsProcessing(false);
          break;
        }
        counter--;
        continue;
      }
    }
  };

  const _getPeriyots = async (urlSystem) => {
    setIsProcessing(true);

    let counter = 5;
    while (true) {
      try {
        const urlSystemDetay = `${urlSystem}intervals.php?_=${timestamp}`;
        const formationResponse = await axios.get(urlSystemDetay);
        var dataDummy = [];
        dataDummy.push({
          items: formationResponse.data.data.map(periyotCallback),
        });

        // console.log("periyots completed: ", dataDummy[0]);
        setPeriyots(dataDummy[0]);
        setIsProcessing(false);
        break;
      } catch (error) {
        if (counter === 0) {
          console.log("periyots network error: ", error);
          setIsProcessing(false);
          break;
        }
        counter--;
        continue;
      }
    }
  };

  const _isEmpty = (val) => {
    return val === undefined || val == null || val.length <= 0 ? true : false;
  };

  return (
    <DataContext.Provider
      value={{
        isProcessing,
        formations,
        periyots,
        markets,
        setIsProcessing,
        _getFormastions,
        _getPeriyots,
        _getMarkets,
        _isEmpty,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
