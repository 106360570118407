import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

function cerezPolitikasi() {
  React.useEffect(() => {
    document.title = "Çerez Politikası";
  }, []);
  return (
    <Container className="hakkimizda">
      <Row>
        <Card>
          <Card.Body>
            <h1>Çerezler</h1>
            <p>
              Orphan Yazılım Bilişim Ar-Ge Akademi olarak, www.siriusalgo.com
              web sitemizin kullanımını kolaylaştırmak ve sitemizin kullanımını
              kişiselleştirmek amacıyla çerezler, piksel etiketleri
              ("pikseller") ve yerel saklama teknolojileri kullanmaktayız. Bu
              sayfanın, anılan teknolojilerin niçin kullanıldıklarını ve bunları
              kontrol etmeyi veya- tercihiniz bu yöndeyse- silmeyi anlamanıza
              yardımcı olmasını amaçlıyoruz.
            </p>
            <h1>Çerez Politikası</h1>
            <p>
              Firmamız, web sitemizi ziyaret eden kullanıcılar ve kullanıcıların
              web sitesini kullanımı hakkındaki bilgileri teknik bir iletişim
              dosyası (Çerez-Cookie) kullanarak elde edebilir. Bahsi geçen
              teknik iletişim dosyaları, ana bellekte saklanmak üzere bir
              internet sitesinin kullanıcının tarayıcısına (browser) gönderdiği
              küçük metin dosyalarıdır. Teknik iletişim dosyası site hakkında
              durum ve tercihleri saklayarak İnternet'in kullanımını
              kolaylaştırır.
            </p>
            <p>
              Teknik iletişim dosyası, siteyi kaç kişinin ziyaret ettiğini, bir
              kişinin siteyi hangi amaçla, kaç kez ziyaret ettiğini ve ne kadar
              sitede kaldıkları hakkında istatistiksel bilgileri elde etmeye ve
              kullanıcılar için özel tasarlanmış kullanıcı sayfalarından dinamik
              olarak reklam ve içerik üretilmesine yardımcı olur. Teknik
              iletişim dosyası, ana bellekte veya e-postanızdan veri veya
              başkaca herhangi bir kişisel bilgi almak için tasarlanmamıştır.
              Tarayıcıların pek çoğu başta teknik iletişim dosyasını kabul eder
              biçimde tasarlanmıştır ancak kullanıcılar dilerse teknik iletişim
              dosyasının gelmemesi veya teknik iletişim dosyasının
              gönderildiğinde uyarı verilmesini sağlayacak biçimde ayarları
              değiştirebilirler.
            </p>
          </Card.Body>
        </Card>
      </Row>
    </Container>
  );
}

export default cerezPolitikasi;
