import React, { useState } from "react";
import logo from "../../img/logo.png";
import darklogo from "../../img/dark-logo.png";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  GoogleReCaptchaProvider,
  withGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import { isEqualWith } from "lodash";
const access_token = localStorage.getItem("access_token");

//const sitekey = "6LdOY9MeAAAAAHdODwDYZa79kIy5me8rHYnSdI-5";
const sitekey = "6LeJE0MiAAAAABN16UnZ9KRVWnSMyPKZwi6JEx3r";

const uyeGiris = (props) => {
  const urlSystem = props.url;
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [sonuc, setSonuc] = useState(0);

  function validateForm() {
    return username.length > 0 && password.length > 0;
  }
  const sendLogin = async (postData) => {
    setSonuc(1);
    const res = await fetch(`${urlSystem}login.php`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    });

    if (!res.ok) {
      const { error } = await res.json();
      throw new Error(error);
    }

    const data = await res.json();
    if (data.code === 0) {
      // console.log("data", data);
      localStorage.setItem(
        "subscription",
        JSON.stringify(data.data.subscription)
      );
      localStorage.setItem("remaining", JSON.stringify(data.data.remaining));
      localStorage.setItem("package", JSON.stringify(data.data.package));
      localStorage.setItem("package_id", JSON.stringify(data.data.package_id));
      localStorage.setItem(
        "browserTypeXUID",
        JSON.stringify(data.data.bayz).replaceAll('"', "")
      );
      localStorage.setItem(
        "browserTypeXPkc",
        JSON.stringify(data.data.bayz_package).replaceAll('"', "")
      );
      localStorage.setItem(
        "access_token",
        JSON.stringify(data.data.access_token)
      );
      localStorage.setItem(
        "favorites",
        JSON.stringify(data.data.favorites).replaceAll("/", "")
      );

      if (data.data.subscription === "active") {
        toast.success(
          "Üyelik Bilgileri doğrulandı. Tarama sistemine giriş yapılıyor"
        );
        setInterval(() => {
          window.location.href = "/ana-kumanda";
          // if(data.data.bayz == 1){
          //   window.location.href="/tarama"
          // }else{
          //   window.location.href="/formasyonlar"
          // }
        }, 2000);
      }
      if (data.data.subscription === "passive") {
        toast.warning(
          "Üyelik Bilgileri doğrulandı. Paket satın alma paneline yönlendiriliyorsunuz!"
        );
        setInterval(() => {
          window.location.href = "/paket-satin-al";
        }, 2000);
      }
    } else {
      // console.log(data.data);
      toast.error(data.data);
      setInterval(() => {
        setSonuc(0);
      }, 2000);
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const token = await props.googleReCaptchaProps.executeRecaptcha("uyeGiris");
    // console.log('login:',token)
    const postData = {
      recaptcha: token,
      username: username,
      password: password,
    };
    sendLogin(postData);
  };
  function isAuth(access_token) {
    if (access_token) {
      setInterval(() => {
        window.location.href = "/profilim";
      }, 100);
    }
  }
  React.useEffect(() => {
    isAuth(access_token);
    document.title = "Üye Giriş Ekranı";
  }, []);

  return (
    <Container fluid className="videolar">
      <Row>
        <div className="justify-content-around">
          <Container className="formPanel" style={{ width: 500 }}>
            <Row className="d-flex justify-content-around text-center">
              <img
                src={props.theme == "light" ? logo : darklogo}
                className="imgGiris"
                alt="Sirius Algo"
              />
            </Row>
            <ToastContainer />
            <Form
              className="uyeGiris"
              name="normal_login"
              onSubmit={handleSubmit}
            >
              <Row className="mb-3">
                <Form.Group controlId="formGridAd">
                  <Form.Control
                    type="text"
                    name="username"
                    placeholder="E-posta Adresi"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    rules={[
                      {
                        required: true,
                        message: "Lütfen e-posta adresinizi giriniz!",
                      },
                    ]}
                  />
                </Form.Group>

                <Form.Group controlId="formGridSfire">
                  <Form.Control
                    type="password"
                    name="sifre"
                    placeholder="Şifreniz"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    rules={[
                      {
                        required: true,
                        message: "Lütfen Şifreyi giriniz!",
                      },
                    ]}
                  />
                </Form.Group>
              </Row>
              <Row className="d-flex justify-content-around text-center">
                <Button
                  variant="primary"
                  className="uyeBtn"
                  type="submit"
                  disabled={sonuc == 1 ? true : false}
                >
                  Üye Girişi
                </Button>
              </Row>
              <Row style={{ marginTop: 25 }}>
                <Form.Group
                  as={Col}
                  className="mb-3 me-2"
                  id="formGridCheckbox"
                ></Form.Group>
                <Form.Group as={Col} className="mb-3 formLink">
                  <Link to="/uye-parola" className="formLink">
                    Parolamı Unuttum
                  </Link>
                </Form.Group>
              </Row>
            </Form>
          </Container>
        </div>
      </Row>
    </Container>
  );
};

const WrappedLogin = withGoogleReCaptcha(uyeGiris);

function Login(props) {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={sitekey}>
      <WrappedLogin url={props.url} theme={props.theme} />
    </GoogleReCaptchaProvider>
  );
}
export default Login;
