import React, { Suspense, useEffect, useState } from "react";
import { Container, Row, Col, Nav, Spinner } from "react-bootstrap";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import UyeGiris from "./pages/uyeGiris";
import UyeKayit from "./pages/uyeKayit";
import logo from "../img/logo.png";
import darklogo from "../img/dark-logo.png";
import Header from "../components/header";
import MenuPanel from "../components/menu";
import routes from "../routes";
import { ToastContainer } from "react-toastify";
import LoadingSpinner from "./loading";
import ScrollToTop from "react-scroll-to-top";
import axios from "axios";

function Homepage(props) {
  const urlSystem = props.url;
  const location = useLocation();
  const navigate = useNavigate();
  const isActive = location.pathname;
  const [isHome, setIsHome] = useState(0);
  const [isSub, setIsSub] = useState(0);
  const [isAccess, setAccess] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const access_token = localStorage.getItem("access_token");

  function RequireAuth({ children }) {
    let location = useLocation();

    if (!access_token) {
      return <Navigate to="/uye-cikis" state={{ from: location }} replace />;
    }
    return children;
  }
  const getUserInfo = async () => {
    setIsLoading(true);
    if (access_token) {
      const postData = {
        access_token: access_token.replaceAll('"', ""),
      };
      let res = await axios({
        method: "post",
        url: `${urlSystem}user-info.php`,
        data: postData,
      });
      if (res.status === 200) {
        // test for status you want, etc
        console.log(res.status);
      }
      // console.log("userInfo", res.data);
      // Don't forget to return something
      if (res.data.code === 0) {
        localStorage.setItem(
          "subscription",
          JSON.stringify(res.data.data.subscription)
        );
        localStorage.setItem(
          "remaining",
          JSON.stringify(res.data.data.remaining)
        );
        localStorage.setItem("package", JSON.stringify(res.data.data.package));
        localStorage.setItem(
          "package_id",
          JSON.stringify(res.data.data.package_id)
        );
        setIsHome(1);
        setIsLoading(false);
      } else {
        setIsHome(0);
        navigate("/uye-cikis", { replace: true });
        setIsLoading(false);
      }
    }
    setIsLoading(false);
  };

  function handleClick(id) {
    if (isSub != id) {
      setIsSub(id);
    } else {
      setIsSub(0);
    }
  }
  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <ToastContainer />
          <Container fluid>
            {isHome == 0 ? (
              location.pathname == "/uye-giris" ? (
                <>
                  <UyeGiris url={urlSystem} theme={props.theme} />
                </>
              ) : location.pathname == "/uye-kayit" ? (
                <>
                  <UyeKayit url={urlSystem} theme={props.theme} />
                </>
              ) : (
                <Row>
                  <div id="intro" className="bg-image vh-100 shadow-1-strong">
                    <div className="maskVideo">
                      <div className="container d-flex align-items-center justify-content-center text-center h-100">
                        <div className="text-white">
                          <h1 className="mb-3">
                            Siri<span style={{ color: "#5fb045" }}>us</span>{" "}
                            Algo
                          </h1>
                          <h5 className="mb-4">
                            Finans Dünyası'nın Ayrıcalıklarına Hoşgeldiniz
                          </h5>
                          <a
                            className="btn btn-outline-light btn-lg m-2"
                            href="/uye-giris"
                            role="button"
                            rel="nofollow"
                          >
                            Üye Giriş
                          </a>
                          <a
                            className="btn btn-outline-light btn-lg m-2"
                            href="/uye-kayit"
                            role="button"
                          >
                            Üye Kayıt
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </Row>
              )
            ) : location.pathname == "/uye-giris" ? (
              <>
                <UyeGiris />
              </>
            ) : (
              <Row>
                <div className="col-xl-2 col-md-3 SideBarNav d-flex flex-column">
                  <Row>
                    <Col>
                      <div className="logoContainer">
                        <img
                          className="logo"
                          src={props.theme == "light" ? logo : darklogo}
                          alt="Sirius Algo"
                        />
                      </div>

                      <Nav
                        defaultActiveKey="/"
                        className="navSideMenu flex-column"
                      >
                        <MenuPanel
                          theme={props.theme}
                          changeTheme={props.changeTheme}
                        />
                      </Nav>
                    </Col>
                  </Row>
                </div>
                <div className="col wrapper d-flex flex-column min-vh-100">
                  <Row>
                    <Header
                      logo={logo}
                      theme={props.theme}
                      changeTheme={props.changeTheme}
                    />
                    <div className="body flex-grow-1 px-0">
                      <Suspense fallback={<Spinner animation="grow" />}>
                        <Routes>
                          {routes.map((route, idx) => {
                            return (
                              route.element && (
                                <Route
                                  key={idx}
                                  path={route.path}
                                  exact={route.exact}
                                  name={route.name}
                                  element={
                                    route.exact == false ? (
                                      <RequireAuth>
                                        <route.element
                                          url={urlSystem}
                                          theme={props.theme}
                                          changeTheme={props.changeTheme}
                                        />
                                      </RequireAuth>
                                    ) : (
                                      <route.element
                                        url={urlSystem}
                                        theme={props.theme}
                                        changeTheme={props.changeTheme}
                                      />
                                    )
                                  }
                                />
                              )
                            );
                          })}
                        </Routes>
                      </Suspense>
                      <ScrollToTop smooth />
                    </div>
                  </Row>
                </div>
              </Row>
            )}
          </Container>
        </>
      )}
    </>
  );
}

export default Homepage;
