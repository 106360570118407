import React from "react";
import { Container, Row, Card, Col, Button } from "react-bootstrap";
import logo from "../../img/logo.png";
import darklogo from "../../img/dark-logo.png";

function tradingview(props) {
  React.useEffect(() => {
    document.title = "Tradingview";
  }, []);
  return (
    <Container
      fluid
      className="videolar bg-image vh-100 shadow-1-strong"
      style={{ height: "100vh", backgroundColor: "black" }}
    >
      <Row>
        <div className="justify-content-around">
          <Container className="formPanel" style={{ width: 900 }}>
            <Row
              className="d-flex justify-content-around text-center"
              style={{ marginBottom: 120 }}
            >
              <img
                src={props.theme == "light" ? logo : darklogo}
                className="imgGiris"
                alt="Sirius Algo"
              />
            </Row>
            <Row style={{ backgroundColor: "#ffffff50", padding: 50 }}>
              <Col style={{ color: "white" }}>
                <h1 style={{ color: "white" }}>TradingView</h1>
                <p style={{ color: "white" }}>
                  Eğer bütçenizi aşmayan ve uygun maliyetli çözümler
                  arıyorsanız, en iyi ücretsiz{" "}
                  <a
                    style={{ color: "#7016d0" }}
                    href="https://tr.tradingview.com/screener/"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    hisse senedi tarayıcıları
                  </a>{" "}
                  hakkında değerli bilgiler edinmek isteyebilirsiniz.{" "}
                  <a
                    style={{ color: "#7016d0" }}
                    href="https://tr.tradingview.com/symbols/DXY"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Dolar Endeksi
                  </a>{" "}
                  alımı için adım adım rehberimize göz atın ve yatırım
                  portföyünüzü çeşitlendirmek için izlemeniz gereken
                  stratejileri keşfedin. Ayrıca, ekonomi takvimini nasıl etkili
                  bir şekilde takip edebileceğinizi anlamak ve öngörü gücünden
                  nasıl faydalanabileceğinizi öğrenmek için içgörüler elde edin.
                </p>{" "}
                <div style={{ marginTop: "20px", textAlign: "center" }}>
                  <Button variant="primary">
                    {" "}
                    <a
                      href="https://tr.tradingview.com/widget/"
                      target="_blank"
                      class="button"
                      rel="noopener"
                      style={{ color: "white" }}
                    >
                      TradingView
                    </a>{" "}
                  </Button>
                  <p style={{ color: "white" }} class="message">
                    Daha fazla bilgi için tıklayınız.
                  </p>{" "}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Row>
    </Container>
  );
}

export default tradingview;
