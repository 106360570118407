import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import KullaniciSozlesme from "./kullaniciSozlesme"
import GizlilikPolitikasi from "./gizlilikPolitikasi"
import CerezPolitikasi from "./cerezPolitikasi"
import { Container, Row, Col, Form, Button, InputGroup, Modal } from "react-bootstrap";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MaskedInput from 'react-text-mask'

import {
  GoogleReCaptchaProvider,
  withGoogleReCaptcha
} from 'react-google-recaptcha-v3';

//const sitekey = "6LdOY9MeAAAAAHdODwDYZa79kIy5me8rHYnSdI-5";
const sitekey = "6LeJE0MiAAAAABN16UnZ9KRVWnSMyPKZwi6JEx3r";

function uyeParola(props) {
  const urlSystem = props.url;
  const [email, setEmail] = useState("");
  const [sonuc, setSonuc] = useState(0);

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const handleSubmit = async (event) => {
    setSonuc(1)
    if (!isValidEmail(email)) {
        toast.error("Lütfen geçerli bir email adresi giriniz!");
        setInterval(() => {
            setSonuc(0);
          }, 2000);
    } else {

        
        event.preventDefault();
        const token = await props.googleReCaptchaProps.executeRecaptcha("pass");
        // console.log("pass:", token);
        const postData = {
          recaptcha: token,
          email: email,
          
        };
        let res = await axios({
          method: "post",
          url: `${urlSystem}forgot-password.php`,
          data: postData,
        });
        if (res.status === 200) {
          console.log(res.status);
        }
        if (res.data.code === 0) {
          toast.success(res.data.data);
          setInterval(() => {
            window.location.href = "/";
          }, 2000);
        } else {
          toast.error(res.data.data);
          setInterval(() => {
            setSonuc(0);
          }, 2000);
        }
    }
  
  }
  React.useEffect(() => {
    document.title = 'Üye Parola Sıfırlama';
  }, []);

  return (
    <Container fluid>
      <ToastContainer />
      <Row>
        <div className="formUst justify-content-around">
          <Container className="formPanel">
            <Row className="d-flex justify-content-around text-center">
              <Col>
                <Link to="/uye-kayit" className="tabBtn tabAktif">
                  Üyelik
                </Link>
              </Col>
            </Row>

              <Form inline="true" className="d-flex mt-5 tabBtn" onSubmit={handleSubmit} style={{paddingLeft:30,paddingRight:30}}>
                <InputGroup className="m-3">
                <Form.Control
                    type="email"
                    className="form-control"
                    placeholder="E-posta adresini giriniz"
                    id="my-input-id"
                    onChange={(event) => setEmail(event.target.value)}
                  />
          
                  <Button variant="outline-secondary" id="button-addon2"  type="submit" disabled={sonuc == 1 ? true: false }>
                    Gönder
                  </Button>
                </InputGroup>
              </Form>
            
          </Container>
        </div>
      </Row>
    </Container>
  );
}

const WrappedRegister = withGoogleReCaptcha(uyeParola);

function Register(props) {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={sitekey}>
      <WrappedRegister url={props.url}/>
    </GoogleReCaptchaProvider>
  )
}
export default Register;
