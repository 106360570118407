import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  Modal,
} from "react-bootstrap";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  GoogleReCaptchaProvider,
  withGoogleReCaptcha,
} from "react-google-recaptcha-v3";

//const sitekey = "6LdOY9MeAAAAAHdODwDYZa79kIy5me8rHYnSdI-5";
const sitekey = "6LeJE0MiAAAAABN16UnZ9KRVWnSMyPKZwi6JEx3r";

function uyeAktivasyon(props) {
  const urlSystem = props.url;
  const { code } = useParams();
  const [sonuc, setSonuc] = useState(0);

  const getAktivasyon = async (event) => {
    setSonuc(1);
    event.preventDefault();
    const token = await props.googleReCaptchaProps.executeRecaptcha('uyeAktivasyon');
    // console.log('uyeAktivasyon:',token)
    const postData = {
      recaptcha: token,
      code: code,
    };
    let res = await axios({
      method: "post",
      url: `${urlSystem}activation.php`,
      data: postData,
    });
    if (res.status === 200) {
      // test for status you want, etc
      console.log(res.status);
    }
    // console.log("userInfo", res.data);
    // Don't forget to return something
    if (res.data.code === 0) {
      toast.success(res.data.data);
      setSonuc(0);
      setInterval(() => {
        window.location.href = "/uye-giris";
      }, 3000);
    } else {
      toast.error(res.data.data);
      setSonuc(0);
    }
  };

  useEffect(() => {
    //getAktivasyon();
    document.title = 'Üyelik Aktivasyon';
  }, []);
  return (
    <Container fluid>
      <ToastContainer />
      <Row>
        <div className="formUst justify-content-around">
          <Container className="formPanel">
            <Row className="d-flex justify-content-around text-center">
              <Col>
                <Link to="#" className="tabBtn tabAktif">
                  Üyelik Aktivasyon
                </Link>
              </Col>
            </Row>

            <Form
              className="uyeGiris"
              name="normal_login"
              onSubmit={getAktivasyon}
              style={{padding:150}}
            >
              <Row className="mb-3">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <Form.Check
                    type="checkbox"
                    label={
                      <span style={{ fontSize: 16 }}>Üyeliğimi Aktif Et</span>
                    }
                    required
                  />
                </Form.Group>
              </Row>
              <Row className="d-flex justify-content-around text-center">
                <Button
                  variant="primary"
                  className="uyeBtn"
                  type="submit"
                  disabled={sonuc == 1 ? true : false}
                >
                  Gönder
                </Button>
              </Row>
            </Form>
          </Container>
        </div>
      </Row>
    </Container>
  );
}

const WrappedRegister = withGoogleReCaptcha(uyeAktivasyon);

function Register(props) {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={sitekey}>
      <WrappedRegister url={props.url} />
    </GoogleReCaptchaProvider>
  );
}
export default Register;
