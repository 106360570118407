import React from "react";
const Homepage = React.lazy(() => import("./components/homepage"));
const Dashboard = React.lazy(() => import("./components/pages/dashboard"));
const Hakkimizda = React.lazy(() => import("./components/pages/hakkimizda"));
const Sss = React.lazy(() => import("./components/pages/sss"));
const Iletisim = React.lazy(() => import("./components/pages/iletisim"));
const Videolar = React.lazy(() => import("./components/pages/videolar"));
const UyeGiris = React.lazy(() => import("./components/pages/uyeGiris"));
const UyeCikis = React.lazy(() => import("./components/pages/uyeCikis"));
const UyeKayit = React.lazy(() => import("./components/pages/uyeKayit"));
const UyeSatinAl = React.lazy(() => import("./components/pages/uyeSatinAl"));
const Profilim = React.lazy(() => import("./components/pages/profilim"));
const OdemeGecmisi = React.lazy(() =>
  import("./components/pages/odemeGecmisi")
);
const Favorilerim = React.lazy(() => import("./components/pages/favorilerim"));
const FavoriAramalar = React.lazy(() =>
  import("./components/pages/favoriAramalar")
);
const Tarama = React.lazy(() => import("./components/pages/taramaAll"));
const AnaKumanda = React.lazy(() => import("./components/pages/anaKumanda"));
const Tarama2 = React.lazy(() => import("./components/pages/taramaAll2"));
const Harmonics = React.lazy(() =>
  import("./components/pages/taramaHarmonics")
);
const Patterns = React.lazy(() =>
  import("./components/pages/taramaFormations")
);
const Fibometre = React.lazy(() =>
  import("./components/pages/taramaFibometre")
);
const TaramaGrafik = React.lazy(() => import("./components/pages/taramaBayz"));
const Indikator = React.lazy(() =>
  import("./components/pages/taramaIndikator")
);
const Strateji2 = React.lazy(() =>
  import("./components/pages/taramaStrateji2")
);
const Backtest = React.lazy(() => import("./components/pages/backtest"));
const Destek = React.lazy(() => import("./components/pages/destek"));
const Tanitim = React.lazy(() => import("./components/pages/tanitim"));
const Kvkk = React.lazy(() => import("./components/pages/kvkk"));
const kullaniciSozlesme = React.lazy(() =>
  import("./components/pages/kullaniciSozlesme")
);
const gizlilikPolitikasi = React.lazy(() =>
  import("./components/pages/gizlilikPolitikasi")
);
const cerezPolitikasi = React.lazy(() =>
  import("./components/pages/cerezPolitikasi")
);
const sorumlulukReddi = React.lazy(() =>
  import("./components/pages/sorumlulukReddi")
);
const OdemeCevap = React.lazy(() => import("./components/pages/odemeCevap"));
const YeniDestek = React.lazy(() => import("./components/pages/yeniDestek"));
const Grafik = React.lazy(() => import("./components/pages/grafik"));
const OrphanOzelDipEndeksi = React.lazy(() =>
  import("./components/pages/orphanOzelDipEndeksi")
);
const OrphanOzelKesisimEndeksi = React.lazy(() =>
  import("./components/pages/orphanOzelKesisimEndeksi")
);
const HeikinAlaDonenler = React.lazy(() =>
  import("./components/pages/heikinAlaDonenler")
);
const UyeAktivasyon = React.lazy(() =>
  import("./components/pages/uyeAktivasyon")
);
const Error404 = React.lazy(() => import("./components/pages/error404"));

const routes = [
  { path: "/", exact: true, name: "Homepage", element: Dashboard, exact: true },
  { path: "/dashboard", name: "Homepage", element: Homepage, exact: true },
  { path: "/uye-giris", name: "UyeGiris", element: UyeGiris, exact: true },
  { path: "/uye-kayit", name: "UyeKayit", element: UyeKayit, exact: true },
  { path: "/ana-kumanda", name: "Tarama", element: AnaKumanda, exact: false },
  { path: "/tarama", name: "Tarama2", element: Tarama, exact: false },
  {
    path: "/ana-kumanda/:taramaId",
    name: "Tarama",
    element: AnaKumanda,
    exact: false,
  },
  {
    path: "/favorilerim",
    name: "Favorilerim",
    element: Favorilerim,
    exact: false,
  },
  {
    path: "/favori-aramalarim",
    name: "Favori Aramalar",
    element: FavoriAramalar,
    exact: false,
  },
  {
    path: "/gelismis-grafik",
    name: "Grafik Paneli",
    element: Grafik,
    exact: false,
  },
  {
    path: "/gelismis-grafik/:grafikName",
    name: "Grafik Paneli",
    element: Grafik,
    exact: false,
  },
  { path: "/strateji", name: "Strateji", element: Strateji2, exact: false },
  {
    path: "/indikatorler",
    name: "Indikator",
    element: Indikator,
    exact: false,
  },
  // { path: "/bayz", name: "Tarama", element: TaramaGrafik, exact: false },
  // {
  //   path: "/bayz/:taramaId",
  //   name: "Tarama",
  //   element: TaramaGrafik,
  //   exact: false,
  // },
  // { path: "/harmonikler", name: "Harmonics", element: Harmonics, exact: false },
  // {
  //   path: "/harmonikler/:taramaId",
  //   name: "Harmonics",
  //   element: Harmonics,
  //   exact: false,
  // },
  // { path: "/formasyonlar", name: "Patterns", element: Patterns, exact: false },
  // {
  //   path: "/formasyonlar/:taramaId",
  //   name: "Patterns",
  //   element: Patterns,
  //   exact: false,
  // },
  // { path: "/fibometre", name: "Fibometre", element: Fibometre, exact: false },
  // {
  //   path: "/fibometre/:taramaId",
  //   name: "Fibometre",
  //   element: Fibometre,
  //   exact: false,
  // },
  { path: "/bayzGrafik", name: "Tarama", element: TaramaGrafik, exact: false },
  {
    path: "/hakkimizda",
    name: "Hakkimizda",
    element: Hakkimizda,
    exact: false,
  },
  {
    path: "/orphan-ozel-dip-endeksi",
    name: "OrphanOzelDipEndeksi",
    element: OrphanOzelDipEndeksi,
    exact: false,
  },
  {
    path: "/orphan-ozel-kesisim-endeksi",
    name: "OrphanOzelKesisimEndeksi",
    element: OrphanOzelKesisimEndeksi,
    exact: false,
  },
  {
    path: "/Heikin-ala-donenler",
    name: "HeikinAlaDonenler",
    element: HeikinAlaDonenler,
    exact: false,
  },
  { path: "/profilim", name: "Profilim", element: Profilim, exact: false },
  {
    path: "/odeme-gecmisi",
    name: "OdemeGecmisi",
    element: OdemeGecmisi,
    exact: false,
  },
  { path: "/sss", name: "Sss", element: Sss, exact: false },
  { path: "/tanitim", name: "Tanitim", element: Tanitim, exact: false },
  { path: "/destek", name: "Destek", element: Destek, exact: false },
  {
    path: "/destek/:destekId",
    name: "Destek Detay",
    element: Destek,
    exact: false,
  },
  {
    path: "/yeni-destek",
    name: "YeniDestek",
    element: YeniDestek,
    exact: false,
  },
  {
    path: "/paket-satin-al",
    name: "UyeSatinAl",
    element: UyeSatinAl,
    exact: false,
  },
  {
    path: "/payment-received",
    name: "OdemeCevap",
    element: OdemeCevap,
    exact: false,
  },
  {
    path: "/payment-received/:cevapId",
    name: "OdemeCevap",
    element: OdemeCevap,
    exact: false,
  },
  { path: "/kvkk", name: "Kvkk", element: Kvkk, exact: false },
  {
    path: "/kullanici-sozlesmesi",
    name: "kullaniciSozlesme",
    element: kullaniciSozlesme,
    exact: false,
  },
  {
    path: "/gizlilik-politikasi",
    name: "gizlilikPolitikasi",
    element: gizlilikPolitikasi,
    exact: false,
  },
  {
    path: "/cerez-politikasi",
    name: "cerezPolitikasi",
    element: cerezPolitikasi,
    exact: false,
  },
  {
    path: "/sorumluluk-reddi",
    name: "sorumlulukReddi",
    element: sorumlulukReddi,
    exact: false,
  },
  // { path: '/grafik', name: 'grafik', element: Grafik, exact: false },
  // { path: '/grafik/:grafikName', name: 'grafik', element: Grafik, exact: false },
];

export default routes;
