import React, { useState, useEffect } from "react";
import { DataProvider } from "./context/DataContext";
import "./App.css";
import "./css/variables.css";
import "./css/buttons.css";
import "./css/shadow.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import axios from "axios";
import Homepage from "./components/homepage";
import Maintance from "./components/maintance";
import UyeCikis from "./components/pages/uyeCikis";
import UyeParola from "./components/pages/uyeParola";
import TradingView from "./components/pages/tradingview";
import UyeAktivasyon from "./components/pages/uyeAktivasyon";
import UyeParolaSifirlama from "./components/pages/uyeParolaSifirlama";
import { urlDevSystem, urlProSystem } from "./components/dummyData";
import { ThemeProvider } from "styled-components";
import { createGlobalStyle } from "styled-components";
import packageJson from "../package.json";
import { refreshCacheAndReload } from "./utils/utils";

let date = new Date();
var timestamp = date.getTime();

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${(props) => props.theme.body};
    color: ${(props) => props.theme.text};
  }`;

const LightTheme = {
  iconColor: "#000",
  body: "#fff",
};

const DarkTheme = {
  iconColor: "#fff",
  body: "#17171a",
};

const themes = {
  light: LightTheme,
  dark: DarkTheme,
};

function App() {
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");
  const [version, setVersion] = useState(localStorage.getItem("version"));
  const access_token = localStorage.getItem("access_token");
  const [maintance, setMaintance] = useState(false);

  const _maintenanceCheck = async (urlSystem) => {
    localStorage.setItem("urlSystem", urlSystem);

    let filtreFunc = localStorage.getItem("filtreFunc");
    if (!filtreFunc) {
      localStorage.setItem(
        "filtreFunc",
        JSON.stringify({
          formasyonlar: [],
          indicator: [],
          ortalama: [],
          hacim: [],
        })
      );
    }
    let borsa = localStorage.getItem("borsa");
    if (!borsa) {
      localStorage.setItem("borsa", 0);
    }
    let birlikte = localStorage.getItem("birlikte");
    if (!birlikte) {
      localStorage.setItem("birlikte", 1);
    }
    let token = localStorage.getItem("access_token");
    if (token != null) {
      token = access_token.replaceAll('"', "");
    }
    const postData = {
      check: 1,
      access_token: token,
    };
    let url = `${urlSystem}maintenance.php?check=3&_=${timestamp}`;

    let res = await axios.post(url, postData);
    console.log("resMain", res.data);
    if (res.status === 200) {
      if (res.data.code === 0) {
        setMaintance(res.data.data);
      }
      if (res.data == true) {
        setMaintance(true);
      }
    }
  };
  const development = false;
  let urlSystem = "";
  if (development) {
    urlSystem = urlDevSystem;
  } else {
    urlSystem = urlProSystem;
  }
  function changeTheme() {
    if (theme === "light") {
      localStorage.setItem("theme", "dark");
      setTheme("dark");
    } else {
      setTheme("light");
      localStorage.setItem("theme", "light");
    }
  }
  useEffect(() => {
    _maintenanceCheck(urlSystem);
    // localStorage.setItem("urlSystem", urlSystem);
    if (version) {
      if (packageJson.version !== version) {
        console.log("cleanin caches");
        refreshCacheAndReload();
      }
    } else {
      console.log("version: ", version);
      console.log("setting version: ", packageJson.version);
      localStorage.setItem("version", packageJson.version);
    }
  }, []);
  return (
    <>
      {!maintance ? (
        <ThemeProvider theme={themes[theme]} data-theme={theme}>
          <GlobalStyle theme={themes[theme]} />
          <DataProvider>
            <Router>
              <div className="App" data-theme={theme}>
                <Routes>
                  <Route
                    path="/tradingview"
                    element={<TradingView />}
                    theme={theme}
                  />
                  <Route
                    path="/uye-cikis"
                    element={<UyeCikis url={urlSystem} />}
                  />
                  <Route
                    path="/uye-parola"
                    element={<UyeParola url={urlSystem} />}
                  />
                  <Route
                    path="/uye-parola-sifirlama/:code"
                    element={<UyeParolaSifirlama url={urlSystem} />}
                  />
                  <Route
                    path="/uye-aktivasyon/:code"
                    element={<UyeAktivasyon url={urlSystem} />}
                  />
                  <Route
                    path="*"
                    element={
                      <Homepage
                        url={urlSystem}
                        theme={theme}
                        changeTheme={changeTheme}
                      />
                    }
                  />
                </Routes>
              </div>
            </Router>
          </DataProvider>
        </ThemeProvider>
      ) : (
        <Maintance />
      )}
    </>
  );
}

export default App;
