import React, { Component } from 'react'

export default class maintance extends Component {
  render() {
    return (
        <div id="nc-main" className="nc-main bg-cover bg-cc">
        <div className="full-wh">
            <div className="bg-animation">
                <div id='stars'></div>
                <div id='stars2'></div>
                <div id='stars3'></div>
                <div id='stars4'></div>
            </div>
            <div className='maintanceYazi'>Sistem güncelleniyor. Lütfen bekleyiniz.</div>
        </div>
    </div>
    )
  }
}
