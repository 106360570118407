import React, { useState } from "react";
import { Link } from "react-router-dom";
import KullaniciSozlesme from "./kullaniciSozlesme";
import GizlilikPolitikasi from "./gizlilikPolitikasi";
import CerezPolitikasi from "./cerezPolitikasi";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  Modal,
} from "react-bootstrap";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MaskedInput from "react-text-mask";
import ModalVideo from "react-modal-video";
import {
  GoogleReCaptchaProvider,
  withGoogleReCaptcha,
} from "react-google-recaptcha-v3";
const access_token = localStorage.getItem("access_token");

//const sitekey = "6LdOY9MeAAAAAHdODwDYZa79kIy5me8rHYnSdI-5";
const sitekey = "6LeJE0MiAAAAABN16UnZ9KRVWnSMyPKZwi6JEx3r";

function uyeKayit(props) {
  const urlSystem = props.url;
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [isLogin, setIsLogin] = useState(0);
  const [phone1, setPhone1] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [modalVideo, setModalVideo] = useState(false);
  const [page, setPage] = useState(false);
  const [sonuc, setSonuc] = useState(0);

  //const { executeRecaptcha } = useGoogleReCaptcha();
  //const [token, setToken] = useState('');

  const handleClose = () => setModalShow(false);
  const handleShow = (pageName) => {
    setModalShow(true);
    setPage(pageName);
  };

  const sendUser = async (event) => {
    setSonuc(1);

    event.preventDefault();
    const token = await props.googleReCaptchaProps.executeRecaptcha("sendUser");
    //console.log('sendUser:',token)

    const postData = {
      recaptcha: token,
      firstname: firstname.trim(),
      lastname: lastname.trim(),
      email: email.trim(),
      phone: phone.trim(),
      password1: password.trim(),
      password2: password2.trim(),
    };
    let res = await axios({
      method: "post",
      url: `${urlSystem}signup.php`,
      data: postData,
    });
    if (res.status === 200) {
      // test for status you want, etc
      console.log(res.status);
    }
    if (res.data.code === 0) {
      toast.success(
        "Üye Kaydınız gerçkeleşmiştir. E-posta adresinize gelen onaylama mailini kontrol edeniz."
      );
      setInterval(() => {
        window.location.href = "/";
      }, 2000);
    } else {
      //console.log("error", res.data);
      toast.error(res.data.data);
      setInterval(() => {
        setSonuc(0);
      }, 2000);
    }
  };

  const handleSubmit = async (event) => {
    setSonuc(1);

    event.preventDefault();
    const token = await props.googleReCaptchaProps.executeRecaptcha("phone");
    //console.log('phone:',token)
    const phone2 = phone1
      .replaceAll("-", "")
      .replaceAll(" ", "")
      .replaceAll(")", "")
      .replaceAll("(", "");
    setPhone(phone2);
    const postData = {
      recaptcha: token,
      phone: phone2,
    };
    //console.log('phone', phone2)
    let res = await axios({
      method: "post",
      url: `${urlSystem}user-phone.php`,
      data: postData,
    });
    if (res.status === 200) {
      console.log(res.status);
    }
    if (res.data.code === 0) {
      setIsLogin(1);
      setInterval(() => {
        setSonuc(0);
      }, 2000);
    } else {
      setIsLogin(0);
      toast.error(res.data.data);
      setInterval(() => {
        setSonuc(0);
      }, 2000);
    }
  };
  function isAuth(access_token) {
    if (access_token) {
      setInterval(() => {
        window.location.href = "/profilim";
      }, 100);
    }
  }
  React.useEffect(() => {
    isAuth(access_token);
    document.title = "Üye Kayıt Ekranı";
  }, []);

  return (
    <Container fluid>
      <ToastContainer />
      <Row>
        <div className="formUst justify-content-around">
          <Container className="formPanel">
            <Row className="d-flex justify-content-around text-center">
              <Col>
                <Link to="/uye-kayit" className="tabBtn tabAktif">
                  Üyelik
                </Link>
              </Col>
            </Row>
            {!isLogin ? (
              <Form
                inline="true"
                className="d-flex mt-5 tabBtn"
                onSubmit={handleSubmit}
                style={{ paddingLeft: 30, paddingRight: 30 }}
              >
                <InputGroup className="m-3">
                  <MaskedInput
                    mask={[
                      /[1-9]/,
                      /\d/,
                      /\d/,
                      " ",
                      /\d/,
                      /\d/,
                      /\d/,
                      "-",
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                    ]}
                    className="form-control"
                    placeholder="Telefon numarasını giriniz"
                    guide={false}
                    id="my-input-id"
                    onChange={(event) => setPhone1(event.target.value)}
                  />

                  <Button
                    variant="outline-secondary"
                    id="button-addon2"
                    type="submit"
                    disabled={sonuc == 1 ? true : false}
                  >
                    Giriş
                  </Button>
                </InputGroup>
              </Form>
            ) : (
              <Form autoComplete="off" onSubmit={sendUser} className="formTab">
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formGridAd">
                    <Form.Label>Adınız</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Adınız"
                      defaultValue={firstname}
                      onChange={(event) => setFirstname(event.target.value)}
                    />
                  </Form.Group>

                  <Form.Group as={Col} controlId="formGridSoyAd">
                    <Form.Label>Soyadınız</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Soyadınız"
                      defaultValue={lastname}
                      onChange={(event) => setLastname(event.target.value)}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Label>Epostanız</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Epostanız"
                      defaultValue={email}
                      onChange={(event) => setEmail(event.target.value)}
                    />
                  </Form.Group>

                  <Form.Group as={Col} controlId="formGridTelefon">
                    <Form.Label>Telefon</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Telefon"
                      defaultValue={phone}
                      disabled
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Label>Şifreniz</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Şifreniz"
                      defaultValue={password}
                      onChange={(event) => setPassword(event.target.value)}
                    />
                  </Form.Group>

                  <Form.Group as={Col} controlId="formGridTelefon">
                    <Form.Label>Şifreniz (Tekrar)</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Şifreniz (Tekrar)"
                      defaultValue={password2}
                      onChange={(event) => setPassword2(event.target.value)}
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Check
                      type="checkbox"
                      label={
                        <span style={{ fontSize: 10 }}>
                          Kutuya tıklayarak,{" "}
                          <a
                            href="#"
                            onClick={() => handleShow("kullanici")}
                            rel="noopener noreferrer"
                          >
                            Kullanıcı Sözleşmesini
                          </a>
                          ,
                          <a
                            href="#"
                            onClick={() => handleShow("gizlilik")}
                            rel="noopener noreferrer"
                          >
                            {" "}
                            Gizlilik Politikasını
                          </a>{" "}
                          ve
                          <a
                            href="#"
                            onClick={() => handleShow("cerez")}
                            rel="noopener noreferrer"
                          >
                            {" "}
                            Çerez Politikasını
                          </a>{" "}
                          okumuş ve kabul etmiş olursunuz.
                        </span>
                      }
                      required
                    />
                  </Form.Group>
                </Row>
                <Row className="d-flex justify-content-around text-center">
                  <Button
                    id="sendBtn"
                    variant="primary"
                    type="submit"
                    disabled={sonuc == 1 ? true : false}
                  >
                    Kaydol
                  </Button>
                </Row>
              </Form>
            )}
            <div className="text-center" style={{ marginTop: "20px" }}>
              <button
                className="btn btn-warning"
                onClick={() => setModalVideo(true)}
              >
                Kayıt Süreci Videosu
              </button>
            </div>
          </Container>
        </div>
        {modalShow && (
          <Modal
            show={modalShow}
            onHide={handleClose}
            dialogClassName="modal-80w"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              {page == "kullanici" ? (
                <KullaniciSozlesme />
              ) : page == "gizlilik" ? (
                <GizlilikPolitikasi />
              ) : page == "cerez" ? (
                <CerezPolitikasi />
              ) : (
                <></>
              )}
            </Modal.Body>
          </Modal>
        )}
        {modalVideo && (
          <>
            <button className="modal-video-close-btn"></button>
            <ModalVideo
              channel="youtube"
              autoplay
              isOpen={modalVideo}
              videoId="nT6Qr9VwuLs"
              onClose={() => setModalVideo(false)}
            />
          </>
        )}
      </Row>
    </Container>
  );
}

const WrappedRegister = withGoogleReCaptcha(uyeKayit);

function Register(props) {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={sitekey}>
      <WrappedRegister url={props.url} />
    </GoogleReCaptchaProvider>
  );
}
export default Register;
