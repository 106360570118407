import React, {useState} from 'react'
import { useParams } from "react-router-dom";
import {
  Container,
  Row,
  Form,
  Button
} from "react-bootstrap";
import axios from "axios";
import orphanLogo from "../../img/logo.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  GoogleReCaptchaProvider,
  withGoogleReCaptcha
} from 'react-google-recaptcha-v3';

//const sitekey = "6LdOY9MeAAAAAHdODwDYZa79kIy5me8rHYnSdI-5";
const sitekey = "6LeJE0MiAAAAABN16UnZ9KRVWnSMyPKZwi6JEx3r";

function uyeParolaSifirlama(props) {
  const urlSystem = props.url;
  const { code } = useParams();
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [sonuc, setSonuc] = useState(0);

  const getParolaSifirlama = async (event) => {
      setSonuc(1);
      
    event.preventDefault();
    const token = await props.googleReCaptchaProps.executeRecaptcha('sendUser');
    // console.log('sendUser:',token)
    const postData = {
      recaptcha: token,
      code: code,
      password1: password1,
      password2: password2,
      
    };
    let res = await axios({
      method: "post",
      url: `${urlSystem}password-reset.php`,
      data: postData,
    });
    if (res.status === 200) {
      // test for status you want, etc
      console.log(res.status);
    }
    // console.log("userInfo", res.data);
    // Don't forget to return something
    if (res.data.code === 0) {
        toast.success("Şifreniz başarıyla değiştirilmiştir.");
        //console.log(res.data.data);
        setInterval(() => {
            window.location.href="/uye-giris"
          }, 3000);
    } else {
        toast.error(res.data.data);
        setInterval(() => {
            setSonuc(0)
        }, 2000);
    }
  };
  React.useEffect(() => {
    document.title = 'Üye Parola Sıfırlama Onaylama';
  }, []);

  return (
    <Container fluid className="videolar">
      <Row>
        <div className="justify-content-around">
          <Container className="formPanel" style={{ width: 500 }}>
            <Row className="d-flex justify-content-around text-center">
              <img src={orphanLogo} className="imgGiris" alt="Sirius Algo" />
            </Row>
            <ToastContainer />
            <Form
              className="uyeGiris"
              name="normal_login"
              onSubmit={getParolaSifirlama}
            >
              <Row className="mb-3">
                <Form.Group controlId="formGridAd">
                  <Form.Control
                    type="password"
                    name="password1"
                    placeholder="Yeni Şifreniz"
                    value={password1}
                    onChange={(e) => setPassword1(e.target.value)}
                    rules={[
                      {
                        required: true,
                        message: "Lütfen Şifreyi giriniz!",
                      },
                    ]}
                  />
                </Form.Group>

                <Form.Group controlId="formGridSfire">
                  <Form.Control
                    type="password"
                    name="password2"
                    placeholder="Yeni Şifre Tekrar"
                    value={password2}
                    onChange={(e) => setPassword2(e.target.value)}
                    rules={[
                      {
                        required: true,
                        message: "Lütfen Şifreyi giriniz!",
                      },
                    ]}
                  />
                </Form.Group>
              </Row>
              <Row className="d-flex justify-content-around text-center">
                <Button
                  variant="primary"
                  className="uyeBtn"
                  type="submit"
                  disabled={sonuc == 1 ? true: false }
                >
                  Şifre Değiştir
                </Button>
              </Row>
            </Form>
          </Container>
        </div>
      </Row>
    </Container>
  )
}

const WrappedSifirlama = withGoogleReCaptcha(uyeParolaSifirlama);

function Sifirlama(props) {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={sitekey}>
      <WrappedSifirlama url={props.url}/>
    </GoogleReCaptchaProvider>
  )
}
export default Sifirlama;